import React, { Component } from "react";
import './App.css';
import PhotoContainer from './PhotoContainer';

// function App() {
//   const [vscoImages, setVscoImages] = useState({});

//   const makeAPICall = async () => {
//     try {
//       const response = await fetch('https://backend.jeremy-gonzalez.com/user/ip', {mode:'cors'});
//       const data = await response.json();
//       setVscoImages(data)
//       console.log({ data })
//     }
//     catch (e) {
//       console.log(e)
//     }
//   }
  
//   useEffect(() => {
//     makeAPICall();
//   }, [])


//   return (
//     <section className="app">
//       <p>Here are some pictures</p>
//       <PhotoContainer photos={vscoImages} />
//     </section>
//   );
// }

class App extends Component {
  constructor() {
    super();
    this.state = {
      photos: []
    };
  }

  componentDidMount() {
    fetch('https://backend.jeremy-gonzalez.com/user/ip', {mode:'cors'})
      .then(response => {
        if (!response.ok) {
          throw Error("Error fetching vsco images");
        }
        return response.json()
      .then(allData => {
        this.setState({ photos: allData });
          })
      .catch(err => {
        throw Error(err.message);
        });
      }
    );
  }

  render() {
    return (
      <section className="app">
        <p>Here are some pictures</p>
        <PhotoContainer photos={this.state.photos} />
      </section>
    );
  }
}

export default App;