import React from 'react';

const Photo = (props) => {
    return (
        <section class="img">
            <img src={props.url} alt="jeremy vsco" width="400" height="500"/>
        </section>
    )
}

export default Photo